<template>
  <b-card-actions action-collapse ref="refreshCard" title="Daftar Karyawan">
    <b-row class="align-items-center">
      <b-col md="6" sm="12" class="my-1">
        <b-button v-if="allowCreate()" @click.prevent="add" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          Tambah
        </b-button>
      </b-col>
      <b-col md="6" sm="12" class="my-1" v-if="!isCabang">
        <div class="d-flex justify-content-end">
          <b-button @click.prevent="exportKaryawan" variant="outline-primary">Export</b-button>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-model="showModal"
      id="modal-tambah"
      size="xl"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      title="Form"
    >
      <validation-observer ref="formKaryawan">
        <b-row>
        <b-col cols="4">
          <b-form>
            <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading"><feather-icon icon="UserIcon" size="19" class="mr-1" />Data Personal</h2>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>

              <!-- NIK -->
              <b-col cols="12">
                <b-form-group label="NIK" label-for="v-nik">
                  <validation-provider
                    #default="{ errors }"
                    name="nik"
                    :rules="{ required: true, min: 2}"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="form.nik"
                      id="v-nik"
                      placeholder="NIK"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Nama Lengkap -->
              <b-col cols="12">
                <b-form-group label="Nama Lengkap" label-for="v-nama-lengkap">
                  <validation-provider
                    #default="{ errors }"
                    name="nama"
                    :rules="{ required: true }"
                  >
                    <b-form-input
                      v-model="form.nama_lengkap"
                      :state="errors.length > 0 ? false : null"
                      id="v-nama-lengkap"
                      placeholder="Nama Lengkap"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Foto -->
              <b-col cols="12">
                <b-form-group label="Foto" label-for="v-file_foto">
                    <input type="file" ref="file_foto" @change="onSelectedImage" />
                    <div class="my-2" v-if="preview_image">
                      <img :src="preview_image" style="width: 100px;" alt="" />
                    </div>
                </b-form-group>
              </b-col>

              <!-- Jenis Kelamin -->
              <b-col cols="6">
                <b-form-group label="Jenis Kelamin" label-for="v-jk">
                  <validation-provider
                    #default="{ errors }"
                    name="jk"
                    :rules="{ required: true }"
                  >
                    <b-form-select
                      :state="errors.length > 0 ? false : null"
                      v-model="form.jk"
                      :options="optjk"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Agama -->
              <b-col cols="6">
                <b-form-group label="Agama" label-for="v-agama">
                  <validation-provider
                    #default="{ errors }"
                    name="agama"
                    :rules="{ required: true }"
                  >
                    <b-form-select
                      :state="errors.length > 0 ? false : null"
                      v-model="form.agama"
                      :options="optagama"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Tempat Lahir -->
              <b-col sm="12" md="6">
                <b-form-group label="Tempat Lahir" label-for="v-tempat_lahir">
                  <validation-provider
                    #default="{ errors }"
                    name="tempat_lahir"
                    :rules="{ required: true }"
                  >
                    <b-form-input
                      v-model="form.tempat_lahir"
                      :state="errors.length > 0 ? false : null"
                      id="v-tempat_lahir"
                      placeholder="Tempat Lahir"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Tanggal Lahir -->
              <b-col sm="12" md="6">
                <b-form-group label="Tanggal Lahir" label-for="v-tanggal_lahir">
                  <validation-provider
                    #default="{ errors }"
                    name="tanggal_lahir"
                    :rules="{ required: true }"
                  >
                        <b-form-input type="date" v-model="form.tanggal_lahir" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Status Perkawinan -->
              <b-col cols="12">
                <b-form-group label="Status Perkawinan" label-for="v-status_perkawinan">
                  <validation-provider
                    #default="{ errors }"
                    name="status_perkawinan"
                    :rules="{ required: true }"
                  >
                    <b-form-select
                      :state="errors.length > 0 ? false : null"
                      v-model="form.status_perkawinan"
                      :options="optstatus_perkawinan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Nomor HP -->
              <b-col cols="12">
                <b-form-group label="Nomor HP" label-for="v-nomor-hp">
                  <validation-provider
                    #default="{ errors }"
                    name="no_hp"
                    :rules="{ min: 10, required: true }"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="form.no_hp"
                      id="v-nomor-hp"
                      placeholder="Nomor HP"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- email -->
              <b-col cols="12">
                <b-form-group label="Email" label-for="v-email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    :rules="{ required: true, email: true }"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="form.email"
                      id="v-email"
                      type="email"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>

        <b-col cols="4">
        <b-form>
          <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading"><feather-icon icon="MapPinIcon" size="19" class="mr-1" />Data Alamat</h2>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                
              <b-col md="12" sm="12">
                <b-form-group label="Provinsi" label-for="v-provinsi">
                  <v-select v-model="form.provinsi_id" :reduce="(option) => option.value" label="text"
                    :options="optprovinsi" @input="getkabkota()" />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group label="Kabupaten/Kota" label-for="v-kabko">
                  <v-select v-model="form.kab_kota_id" :reduce="(option) => option.value" label="text"
                    :options="optkabko" @input="getkecamatan()" />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group label="Kecamatan" label-for="v-kecamatan">
                  <v-select v-model="form.kecamatan_id" :reduce="(option) => option.value" label="text"
                    :options="optkec" @input="getkelurahan()" />
                </b-form-group>
              </b-col>
              <b-col md="12" sm="12">
                <b-form-group label="Kelurahan/Desa" label-for="v-kel">
                  <v-select v-model="form.desa_id" :reduce="(option) => option.value" label="text" :options="optkel" />
                </b-form-group>
              </b-col>

<!-- Alamat -->
<b-col cols="12">
  <b-form-group label="Alamat" label-for="v-alamat">
    <validation-provider
      #default="{ errors }"
      name="nip"
      :rules="{ required: true }"
    >
      <b-form-textarea
        :state="errors.length > 0 ? false : null"
        v-model="form.alamat"
        id="v-alamat"
        placeholder="Alamat Lengkap"
      />

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</b-col>
              </b-row>
          <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading">
                      <feather-icon icon="BookOpenIcon" size="19" class="mr-1" />Data Pendidikan</h2>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>

              <!-- Pendidikan Terakhir -->
              <b-col cols="12">
                <b-form-group
                  label="Pendidikan Terakhir"
                  label-for="v-pendidikan_terakhir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pendidikan_terakhir"
                    :rules="{ required: true }"
                  >
                    <b-form-input
                      v-model="form.pendidikan_terakhir"
                      :state="errors.length > 0 ? false : null"
                      id="v-pendidikan_terakhir"
                      placeholder="Pendidikan Terakhir"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Kampus / Sekolah -->
              <b-col cols="12">
                <b-form-group label="Kampus / Sekolah" label-for="v-kampus_sekolah">
                  <validation-provider
                    #default="{ errors }"
                    name="kampus_sekolah"
                    :rules="{ required: true }"
                  >
                    <b-form-input
                      v-model="form.kampus_sekolah"
                      :state="errors.length > 0 ? false : null"
                      id="v-kampus_sekolah"
                      placeholder="Kampus / Sekolah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Kualifikasi Pendidikan -->
              <b-col cols="12">
                <b-form-group
                  label="Kualifikasi Pendidikan"
                  label-for="v-kualifikasi_pendidikan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="kualifikasi_pendidikan"
                    :rules="{ required: true }"
                  >
                    <b-form-input
                      v-model="form.kualifikasi_pendidikan"
                      :state="errors.length > 0 ? false : null"
                      id="v-kualifikasi_pendidikan"
                      placeholder="Kualifikasi Pendidikan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
          </b-row>
        </b-form>
            </b-col>

<b-col cols="4">
        <b-form>
          <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading"><feather-icon icon="UserCheckIcon" size="19" class="mr-1" />Data Kepegawaian</h2>
                  </b-alert>
                </b-col>
        </b-row>
              <b-row>
            <!-- NIP -->
            <b-col cols="12">
              <b-form-group label="NIP" label-for="v-nip">
                <validation-provider
                  #default="{ errors }"
                  name="nip"
                  :rules="{ required: true, min: 2 }"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="form.nip"
                    id="v-nip"
                    placeholder="NIP"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Jabatan -->
            <b-col cols="12">
              <b-form-group label="Jabatan" label-for="v-jabatan">
                <validation-provider
                  #default="{ errors }"
                  name="jabatan"
                  :rules="{ required: true }"
                >
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.jabatan_id"
                    :options="optjabatan_id"
                    label="Jabatan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Status Karyawan -->
            <b-col cols="12">
              <b-form-group label="Status Karyawan" label-for="v-status_karyawan">
                <validation-provider
                  #default="{ errors }"
                  name="status_karyawan"
                  :rules="{ required: true }"
                >
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.status_karyawan"
                    :options="optstatus_karyawan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Status SP -->
            <b-col cols="12">
              <b-form-group label="Status SP" label-for="v-status_sp">
                <validation-provider
                  #default="{ errors }"
                  name="status_sp"
                  :rules="{ required: true }"
                >
                  <b-form-select
                    :state="errors.length > 0 ? false : null"
                    v-model="form.status_sp"
                    :options="optstatus_sp"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Masa Kerja -->
            <b-col cols="12">
              <b-form-group label="Masa Kerja" label-for="v-masa_kerja">
                <validation-provider
                  #default="{ errors }"
                  name="masa_kerja"
                  :rules="{ required: true }"
                >
                  <b-form-input
                    v-model="form.masa_kerja"
                    :state="errors.length > 0 ? false : null"
                    id="v-masa_kerja"
                    placeholder="Masa Kerja"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Grade Karyawan -->
            <b-col cols="12">
              <b-form-group label="Grade Karyawan" label-for="v-grade_karyawan">
                <validation-provider
                  #default="{ errors }"
                  name="grade_karyawan"
                  :rules="{ required: true }"
                >
                  <b-form-input
                    v-model="form.grade_karyawan"
                    :state="errors.length > 0 ? false : null"
                    id="v-grade_karyawan"
                    placeholder="Grade Karyawan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="submit"
              >
                {{ label || "Submit" }}
              </b-button>
            </b-col>
        </b-form>
            </b-col>
            
        </b-row>

            
      </validation-observer>
    </b-modal>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(foto)="{item}">
            <img v-if="item.url_foto" style="width: 50px;" :src="item.url_foto" alt="Foto" />
            <feather-icon v-else icon="UserIcon" size="32" />
          </template>
          <template #cell(jk)="data">
            <b-badge :variant="jk[1][data.value]">
              {{ jk[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(jabatan_id)="data">
            <b-badge variant="dark">
              {{ getJabatanName(data.item) }}
            </b-badge>
          </template>

          <template #cell(actions)="row">
            <b-button
              v-if="allowUpdate()"
              v-b-tooltip.hover.right="'Ubah'"
              size="sm"
              @click="edit(row.item)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="allowDelete()"
              v-b-tooltip.hover.right="'Hapus'"
              size="sm"
              @click="remove(row.item)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,BFormFile,
  VBTooltip,
  BAvatar,BAlert,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
} from "bootstrap-vue";
import service from '@/services'
export default {
  components: {
    vSelect,
    ValidationProvider,BAlert,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BModal,BFormFile,
    BFormDatepicker,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      optprovinsi: [],
      optkabko: [],
      optkec: [],
      optkel: [],
      required,
      email,
      confirmed,
      password,
      showModal: false,
      label: "Submit",
      form: {
        provinsi_id: null,
        kab_kota_id: null,
        kecamatan_id: null,
        desa_id: null, // OPTIONAL
        file_foto: null,
        nama_lengkap: null,
        no_hp: null,
        email: null,
        nik: null,
        nip: null,
        alamat: null,
        jabatan_id: null,
        jk: null, //Di isi dengan 1 = Laki-Laki OR 2 = Perempuan
        agama: null, //Di isi dengan 1 = ISLAM OR 2 = KRISTEN OR 3 = KATOLIK OR 4 = HINDU OR 5 = BUDDHA OR 6 = KHONGHUCU
        status_perkawinan: null, // Di isi dengan 1 = Lajang OR 2 = Menikah OR 3 = Duda/Janda
        status_karyawan: null, //Di isi dengan 1 = Aktif Or 0 = Tidak Aktif
        status_sp: null, // Di isi dengan 0 = Tidak Mempunyai Sp Or 1 = Sp 1 Or 2 = Sp 2 Or 3 = Sp 3
        kampus_sekolah: "",
        pendidikan_terakhir: "",
        kualifikasi_pendidikan: "",
        masa_kerja: "",
        grade_karyawan: "",
        file_foto: "",
        tempat_lahir: "",
        tanggal_lahir: "", //Format 2022-01-22
      },
      optjabatan_id: [],
      optjk: [
        { value: 1, text: "Laki-laki" },
        { value: 2, text: "Perempuan" },
      ],
      optagama: [
        { value: 1, text: "Islam" },
        { value: 2, text: "Kristen" },
        { value: 3, text: "Katolik" },
        { value: 4, text: "Hindu" },
        { value: 5, text: "Buddha" },
        { value: 6, text: "Khonghucu" },
      ],
      optstatus_perkawinan: [
        { value: 1, text: "Lajang" },
        { value: 2, text: "Menikah" },
        { value: 3, text: "Duda/Janda" },
      ],
      optstatus_karyawan: [
        { value: 1, text: "Aktif" },
        { value: 2, text: "Tidak Aktif" },
      ],
      optstatus_sp: [
        { value: 0, text: "Tidak mempunyai SP" },
        { value: 1, text: "SP 1" },
        { value: 2, text: "SP 2" },
        { value: 3, text: "SP 3" },
      ],
      value: "",
      formatted: "",
      selected: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "No",
        },
        { key: "foto", label: "Foto" },
        { key: "nama_lengkap", label: "Nama", sortable: true },
        { key: "jk", label: "L/P", sortable: true },
        { key: "jabatan.nama_jabatan", label: "Jabatan", sortable: true },
        { key: "no_hp", label: "No HP", sortable: true },
        { key: "email", label: "E-Mail", sortable: true },
        { key: "alamat", label: "Alamat", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      jk: [
        {
          1: "Laki-laki",
          2: "Perempuan",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      agama: [
        {
          1: "Islam",
          2: "Kristen",
          3: "Katolik",
          4: "Hindu",
          5: "Buddha",
          6: "Khonghucu",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_perkawinan: [
        {
          1: "Lajang",
          2: "Menikah",
          3: "Duda/Janda",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_karyawan: [
        {
          1: "Aktif",
          2: "Tidak Aktif",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_sp: [
        {
          0: "Tidak mempunyai SP",
          1: "SP 1",
          2: "SP 2",
          3: "SP 3",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      preview_image: null
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getData();
    this.getDataJabatan();
    this.getProvinsi()
  },
  methods: {
    async getProvinsi() {
      let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
      provinsi = JSON.parse(JSON.stringify(provinsi));
      let newprovinsi = [];
      provinsi.map((item) => {
        newprovinsi.push({
          text: item.name,
          value: item.id,
        });
      });
      // this.id_provinsi = newprovinsi;
      this.optprovinsi = newprovinsi;
      // });
    },
    async getkabkota() {
      const params = {
        id_provinsi: this.form.provinsi_id ? this.form.provinsi_id : null
      }
      let kabko = await this.$store.dispatch("wilayah/getkabko", params);
      kabko = JSON.parse(JSON.stringify(kabko));
      let newkabko = [];
      kabko.map((item) => {
        newkabko.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkabko = newkabko;
    },
    async getkecamatan() {
      const params = {
        id_kab_kota: this.form.kab_kota_id ? this.form.kab_kota_id : null
      }
      let kec = await this.$store.dispatch("wilayah/getkecamatan", params);
      kec = JSON.parse(JSON.stringify(kec));
      let newkec = [];
      kec.map((item) => {
        newkec.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkec = newkec;
    },
    async getkelurahan() {
      const params = {
        id_kecamatan: this.form.kecamatan_id
      }
      let kel = await this.$store.dispatch("wilayah/getkelurahan", params);
      kel = JSON.parse(JSON.stringify(kel));
      let newkel = [];
      kel.map((item) => {
        newkel.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkel = newkel;
    },
    async clearExportedFile(filename) {
      try {
          const config = {
              url: `/clear-export`,
              method: 'get',
              params: {
                  filename
              },
              headers: {
                  Authorization: `${localStorage.tokenType} ${localStorage.token}`
              }
          }
          const response = await service.sendRequest(config)

          return Promise.resolve(response.data)
      } catch (e) {
          if(e.response && e.response.data) {
              const blob = e.response.data
              const text = await blob.text()
              const errorJson = JSON.parse(text)

              return Promise.reject(errorJson)
          }
          return Promise.reject(e)        
      }
  },
  async print() {
      try {
          const config = {
              url: `/excel-data-karyawan`,
              method: 'get',
              headers: {
                  Authorization: `${localStorage.tokenType} ${localStorage.token}`
              }
          }
          const response = await service.sendRequest(config)

          return Promise.resolve(response.data)
      } catch (e) {
          return this.customRejectPromise(e)
      }
  },
    async exportKaryawan() {
      try {
        this.loading = true

        const response = await this.print()
        await window.open(response)
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split('/')
          const filename = arrFile[ arrFile.length - 1 ]

          await this.clearExportedFile(filename)
        }, 1000)

        this.loading = false
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    onSelectedImage(e) {
      const fileObj = e.target.files || e.dataTransfer.files
      const file = fileObj[0]
      const images_ext = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
      if(!images_ext.includes(file.type)) {
        this.displayError({
          message: 'Harap pilih file foto!'
        })
        return false
      }

      this.preview_image = URL.createObjectURL(file)
      this.form.file_foto = file
      
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    getJabatanName(data) {
      let find = this.jabatan_id.find((item) => item.id == data.jabatan_id);
      return find ? find.nama_jabatan : "-";
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      if (item.jabatan) {
        this.form.jabatan_id = item.jabatan.id;
      }
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data karyawan ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          const fd = new FormData()
          fd.append('data[0][id]', item.id)
          fd.append('data[0][fungsi]', 2)
          this.$store
            .dispatch("karyawan/save", fd)
            .then(() => this.getData())
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        nama_lengkap: null,
        no_hp: null,
        email: null,
        nik: null,
        nip: null,
        alamat: null,
        jabatan_id: null,
        jk: null, //Di isi dengan 1 = Laki-Laki OR 2 = Perempuan
        agama: null, //Di isi dengan 1 = ISLAM OR 2 = KRISTEN OR 3 = KATOLIK OR 4 = HINDU OR 5 = BUDDHA OR 6 = KHONGHUCU
        status_perkawinan: null, // Di isi dengan 1 = Lajang OR 2 = Menikah OR 3 = Duda/Janda
        status_karyawan: null, //Di isi dengan 1 = Aktif Or 0 = Tidak Aktif
        status_sp: null, // Di isi dengan 0 = Tidak Mempunyai Sp Or 1 = Sp 1 Or 2 = Sp 2 Or 3 = Sp 3
        kampus_sekolah: "",
        pendidikan_terakhir: "",
        kualifikasi_pendidikan: "",
        masa_kerja: "",
        grade_karyawan: "",
        file_foto: "",
        tempat_lahir: "",
        tanggal_lahir: "", //Format 2022-01-22
      };
    },
    submit() {
      this.$refs.formKaryawan.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          const fd = new FormData()
          fd.append(`data[0][nama_lengkap]`, this.form.nama_lengkap)
          fd.append(`data[0][no_hp]`, this.form.no_hp)
          fd.append(`data[0][email]`, this.form.email)
          fd.append(`data[0][nik]`, this.form.nik)
          fd.append(`data[0][nip]`, this.form.nip)
          fd.append(`data[0][alamat]`, this.form.alamat)
          fd.append(`data[0][jabatan_id]`, this.form.jabatan_id)
          fd.append(`data[0][jk]`, this.form.jk)
          fd.append(`data[0][agama]`, this.form.agama)
          fd.append(`data[0][status_perkawinan]`, this.form.status_perkawinan)
          fd.append(`data[0][status_karyawan]`, this.form.status_karyawan)
          fd.append(`data[0][status_sp]`, this.form.status_sp)
          fd.append(`data[0][kampus_sekolah]`, this.form.kampus_sekolah)
          fd.append(`data[0][pendidikan_terakhir]`, this.form.pendidikan_terakhir)
          fd.append(`data[0][kualifikasi_pendidikan]`, this.form.kualifikasi_pendidikan)
          fd.append(`data[0][masa_kerja]`, this.form.masa_kerja)
          fd.append(`data[0][grade_karyawan]`, this.form.grade_karyawan)
          fd.append(`data[0][tempat_lahir]`, this.form.tempat_lahir)
          fd.append(`data[0][tanggal_lahir]`, this.form.tanggal_lahir)
          fd.append(`data[0][provinsi_id]`, this.form.provinsi_id)
          fd.append(`data[0][kab_kota_id]`, this.form.kab_kota_id)
          fd.append(`data[0][kecamatan_id]`, this.form.kecamatan_id)
          fd.append(`data[0][desa_id]`, this.form.desa_id)

          if (this.id) {
            fd.append(`data[0][id]`, this.id)
          }

          if(this.form.file_foto) {
            fd.append('file_foto', this.form.file_foto)
          }
      if (this.isCabang){
        fd.append(`data[0][member_id]`, this.user.member_id)
      }
          
          this.$store
            .dispatch("karyawan/save", fd)
            .then(() => {
              this.preview_image = null
              this.label = "Submit";
              this.resetForm();
              this.showModal = false;
              this.getData();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    getData() {
      let payload = {}
      if (this.isCabang){
        payload.member_id = this.user.member_id
      }
      this.$store.dispatch("karyawan/getData", payload).then(() => {
        this.items = this.$store.state.karyawan.datas;
        this.totalRows = this.items.length;
      });
    },
    getDataJabatan() {
      this.$store.dispatch("jabatan/getData", {}).then(() => {
        let jabatan = JSON.parse(JSON.stringify(this.$store.state.jabatan.datas));
        jabatan.map((item) => {
          item.value = item.id;
          item.text = item.nama_jabatan;
        });
        this.optjabatan_id = jabatan;
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
